:global(#root) {
  overflow: auto;
}

:global(.current-draggable-item) {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  z-index: 99;
}

:global(.draggable-hover) {
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-box-shadow:0 0 2px 1px cornflowerblue;
  -moz-box-shadow:0 0 2px 1px cornflowerblue;
  box-shadow:0 0 2px 1px cornflowerblue;
}

:global(.ant-tooltip-inner){
  word-wrap: break-word;
}

:global(.ant-table-thead > tr > th){
  text-align: center!important;
}
:global(.ant-table-tbody){
  text-align: center;

  :global(.text-align-right) {
    // 右对齐
    text-align: right;
  }
  :global(.link) {
    font-weight: normal;
    color: #108ee9;
    cursor: pointer;
  }
  :global(.format-number) {
    // 货币字体
    font-family: "Lucida Sans Typewriter", "Courier New", "Source Code Pro", "Consolas", "DialogInput";
  }
}
:global(body .ant-input-number) {
  width: 128px;
}
:global(.ant-select) {
  //width: 160px!important;
}
:global(.ant-select-dropdown-menu-item){
  white-space: normal!important;
  overflow: auto!important;
  text-overflow: inherit!important;
}
:global(.ant-table-body){
  overflow-x: auto!important;
}
