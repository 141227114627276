.wrapper {
  width: 1000px;
}

.input {
  width: 300px;
}
.text-area {
  width: 500px;
}

.extra-btn {
  margin-left: 10px;
}