.layout{
  min-height: 100vh!important;
  :global(.ant-layout-sider-collapsed){
    :global(.ant-menu-submenu-vertical > .ant-menu-submenu-title:after){
      display: none;
    }
    :global(.anticon){
      font-size: 16px;
      margin-left: 8px;
    }
    :global(.nav-text){
      display: none;
    }
  }
  :global(.ant-layout-header){
    height: inherit;
    line-height: inherit;
    padding: inherit;
  }
}

.platform-item {
  padding: 0 15px;
}