.driver-info {
  .ant-upload-list:nth-child(1) {
    .ant-upload-list-picture-card-container, .ant-upload-list-item {
      width: 450px;
      height: 280px;
      margin-bottom: 0;
    }
  }
}
.person-info {
  .ant-form-item {
    margin-bottom: 10px;
  }
  .form-item-wrapper {
    margin-bottom: 16px;
  }
  .person-info-uploader {
    .ant-form-item-control {
      line-height: 1;
    }
  }
}
