.row{
  &:not(:first-child){
    margin-top: 20px;
  }
  &:not(:last-child){
    margin-bottom: 20px;
  }
}
.dragableRow {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 9;
  width: 100%;
  height: 45px;
  border: 1px solid #efefef;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  cursor: move;
  &:hover {
    background-color: rgb(250, 250, 250);
  }
}
.page {
  &__header {
    &--tips {
      width: 50%;
      margin: 0 auto;
    }
  }
  // &__btns {
  //   // text-align: center;
  // }
  &__btn {
    margin-right: 20px;
  }
  .input--text {
    border: none;
    outline: none;
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}

:global {
  .form-item-wrapper {
    margin-bottom: 30px;
    margin-left: 16.6%;
  }
  .form-item-label-item {
    width: 16.66666666%;
    text-align: right;
    display: inline-block;
  }
  .person-driver-image {
    width: 450px;
    height: 280px;
  }
  .tree-transfer {
    &.ant-transfer-customize-list {
      max-height: 400px;
    }
    .ant-transfer-list .ant-transfer-list-body {
      overflow: auto;
    }
  }
  .ant-table-tbody > tr > td, .ant-table-thead > tr > th {
    padding: 16px 10px;
  }
  .form-inline.ant-form {
    .ant-form-item {
      display: block;
    }
  }
}
.miniRow {
  :global(.ant-row) {
    margin-bottom: 0;
  }
}